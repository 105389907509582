<template>
  <div class="home-wrap">
    <public-banner
      :height="400"
      :is-title="false"
      :is-search="false"
      :is-content="false"
      :is-content-two="true"
      title="Dahua Hardware Open Platform Partner Program"
      content-title="Dahua Hardware Open Platform Partner Program"
      content-desc="Dahua Hardware Open Platform(DHOP) is an open application platform that allows 3rd party applications to be downloaded and installed on Dahua Network Cameras. End-users can find here the right solutions to optimize thier security or loT environment. Flexibility guaranteed as you can always remove the existing application and upload new solutions."
      content-link-text="Supported Models"
      content-link=""
      content-title-two="Milestone & Dahua: Het NIEUWE normaal - hoe kan technologie bijdragen?"
      content-data-two="9th June, 2020"
    />
    <public-label class="test"><i class=""></i>123<br />321</public-label>
    <public-label class="test" title="15465456" shape="round" />
    <public-label class="test" title="15465456" />
    <public-label class="test" title="15465456" />
    <public-label class="test" title="15465456" />
    <public-label class="test" title="15465456" :clickable="true" />
    <public-tabs-round :is-black-text="true" />
    <public-tabs-square />
    <el-checkbox-group v-model="checkList">
      <public-check-box label="id1">名称1</public-check-box>
      <public-check-box label="id3">Entertainment & Conferencing & Religious Entertainment Entertainment Entertainment</public-check-box>
      <public-check-box label="id4">名称4</public-check-box>
      <public-check-box label="id5">名称5</public-check-box>
      <public-check-box label="id6">名称6</public-check-box>
      <public-check-box label="id7">名称7</public-check-box>
    </el-checkbox-group>
    <ss
      title="123"
    />
  </div>
</template>

<script>
import PublicLabel from '@/components/public/PublicLabel.vue'
import PublicTabsRound from '@/components/public/PublicTabsRound.vue'
import PublicTabsSquare from '@/components/public/PublicTabsSquare.vue'
import PublicCheckBox from '@/components/public/PublicCheckBox.vue'
import PublicBanner from '@/components/public/PublicBanner.vue'
export default {
  name: 'Home',
  components: {
    PublicLabel,
    PublicTabsRound,
    PublicTabsSquare,
    PublicCheckBox,
    PublicBanner
  },
  data() {
    return {
      checkList: []
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.home-wrap {
  .test {
    margin-right: 4px;
  }
}
</style>
