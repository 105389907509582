<template>
  <el-checkbox :value="value" v-bind="$attrs" @change="handleChange">
    <div class="public-check-box">
      <i class="el-icon-check check-icon"></i>
      <span v-if="$slots.default" class="name"><slot></slot></span>
      <span v-else class="name">{{ $attrs.label }}</span>
    </div>
  </el-checkbox>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(v) {
      this.$emit('change', v)
    }
  }
}
</script>

<style lang="less" scoped>
.public-check-box {
  position: relative;
  display: inline-block;
  line-height: 40px;
  padding: 9px 48px;
  border-radius: 2px;
  border: 1px solid #E4E7ED;
  cursor: pointer;
  transition: all 0.3s;
  .check-icon {
    position: absolute;
    left: 24px;
    top: 50%;
    width: 28px;
    height: 28px;
    background: rgba(49, 155, 216, 0.1);
    border-radius: 14px;
    line-height: 22px;
    text-align: center;
    transform: scale(0) translateY(-50%);
    transform-origin: 50% 0;
    transition: all 0.3s;
    color: @theme;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
  }
  .name {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    white-space: normal;
    display: block;
  }
}
.el-checkbox {
  margin-right: 16px;
  margin-bottom: 10px;
  max-width: 100%;
}
.is-checked {
  .check-icon {
    transform: scale(1) translateY(-50%);
  }
  .public-check-box {
    padding-left: 72px;
  }
}
/deep/ .el-checkbox__input {
  display: none;
}
/deep/ .el-checkbox__label {
  padding-left: 0;
  line-height: 40px;
}
</style>
